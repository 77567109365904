<template>
    <div class="M2-M3-jjhj">
        <el-dialog
            title="个人详细信息"
            :visible.sync="dialogVisible"
            width="80%"
            >
            <table class="re_search_detail_table">
            <tbody> 
                <tr> 
                <td width="10%;" class="detailtitle">姓名</td>
                <td width="20%;">xxx</td>
                <td width="10%;" class="detailtitle">性别</td>
                <td width="20%;">男</td>
                <td width="10%;" class="detailtitle">出生年月</td>
                <td width="30%;">2020-10-29</td>
                </tr>
                <tr> 
                <td width="10%;" class="detailtitle">身份账号</td>
                <td width="20%;">220220222022202220</td>
                <td width="10%;" class="detailtitle">学历</td>
                <td width="20%;">本科</td>
                <td width="10%;" class="detailtitle">国籍</td>
                <td width="30%;">中国</td>
                </tr>
                <tr> 
                <td width="10%;" class="detailtitle">联系电话</td>
                <td width="20%;">7858981</td>
                <td width="10%;" class="detailtitle">手机号码</td>
                <td width="20%;">131111122222</td>
                <td width="10%;" class="detailtitle">电子邮件</td>
                <td width="30%;">010192929@qq.com</td>
                </tr>
                <tr> 
                <td width="10%;" class="detailtitle">目前地址</td>
                <td width="50%;" colspan="3">北京市大兴区西红门瑞海散去</td>
                <td width="10%;" class="detailtitle">邮政编码</td>
                <td width="30%;">100000</td>
                </tr>
                <tr> 
                <td width="10%;" class="detailtitle">户籍地址</td>
                <td width="50%;" colspan="3">北京市大兴区西红门瑞海散去</td>
                <td width="10%;" class="detailtitle">邮政编码</td>
                <td width="30%;">100000</td>
                </tr>
                <tr> 
                <td width="100%;" colspan="6" class="detailtitle">学习工作履历（从最近年开始填写）</td>
                </tr>
                <tr> 
                <td width="15%;" rowspan="3" class="detailtitle">教育经历*</td>
                <td width="40%;" colspan="2" class="detailtitle">就读学校（高中后）</td>
                <td width="10%;" class="detailtitle">入学时间</td>
                <td width="10%;" class="detailtitle">毕业时间</td>
                <td width="15%;" class="detailtitle">专业及学位</td>
                </tr>
                <tr> 
                <td width="40%;" colspan="2" >就读学校（高中后）</td>
                <td width="10%;" >入学时间</td>
                <td width="10%;" >毕业时间</td>
                <td width="15%;" >专业及学位</td>
                </tr>
                <tr> 
                <td width="40%;" colspan="2" >就读学校（高中后）</td>
                <td width="10%;" >入学时间</td>
                <td width="10%;" >毕业时间</td>
                <td width="15%;" >专业及学位</td>
                </tr>
                <tr> 
                <td width="15%;" rowspan="3" class="detailtitle">工作经历*</td>
                <td width="40%;" colspan="2" class="detailtitle">工作单位</td>
                <td width="10%;" class="detailtitle">入职时间</td>
                <td width="10%;" class="detailtitle">离职时间</td>
                <td width="15%;" class="detailtitle">担任职务</td>
                </tr>
                <tr>  
                <td width="40%;" colspan="2" >就读学校（高中后）</td>
                <td width="10%;" >入学时间</td>
                <td width="10%;" >毕业时间</td>
                <td width="15%;" >专业及学位</td>
                </tr>
                <tr>  
                <td width="40%;" colspan="2" >就读学校（高中后）</td>
                <td width="10%;" >入学时间</td>
                <td width="10%;" >毕业时间</td>
                <td width="15%;" >专业及学位</td>
                </tr>
                <tr> 
                <td width="10%;" class="detailtitle">主要创新成果</td>
                <td width="90%;" colspan="5" >工作单位</td>
                </tr>
                <tr> 
                <td width="10%;" class="detailtitle">重要奖惩情况</td>
                <td width="90%;" colspan="5" >工作单位</td>
                </tr>
            </tbody>
            </table>
        </el-dialog>

        <el-dialog
            title="专利信息"
            :visible.sync="zlshow"
            width="80%"
            >
            <table class="re_search_detail_table">
            <tbody> 
                <tr> 
                    <td width="10%;" class="detailtitle">专利类型</td>
                    <td width="20%;">{{zlDetail.ptype}}</td>
                    <td width="10%;" class="detailtitle">公开（公告）日期</td>
                    <td width="20%;">{{zlDetail.ptype}}</td>
                    <td width="10%;" class="detailtitle">公开（公告）号</td>
                    <td width="30%;">{{zlDetail.gkggh}}</td>
                </tr>
                <tr> 
                    <td width="10%;" class="detailtitle">专利名称</td>
                    <td width="20%;">{{zlDetail.patname}}</td>
                    <td width="10%;" class="detailtitle">申请号</td>
                    <td width="20%;">{{zlDetail.sqh}}</td>
                    <td width="10%;" class="detailtitle">申请日期</td>
                    <td width="30%;">{{zlDetail.sqrq}}</td>
                </tr>
                <tr> 
                    <td width="10%;" class="detailtitle">专利代理机构</td>
                    <td width="90%;" colspan="5">{{zlDetail.zldljg}}</td>
                </tr>
            </tbody>
            </table>
        </el-dialog>
        <div class="M2-M3-content">

            <div class="M2-M3-jjhj-chart">
                <div class="M2-M3-jjhj-title">人员情况表</div>
                
                <div class="tableBox">
                    <table class="re_search_detail_table">
                        <thead>
                            <tr> 
                            <td width="20%;" colspan="2" class="detailtitle"></td>
                            <td width="20%;" colspan="2" class="detailtitle">2017年(前3年)</td>
                            <td width="20%;" colspan="2" class="detailtitle">2018年(前2年)</td>
                            <td width="20%;" colspan="2" class="detailtitle">2019年(上1年)</td>
                            <td width="20%;" colspan="1" class="detailtitle">2020年(12月)</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr> 
                            <td width="20%;" colspan="2" >人员总数(人)</td>
                            <td width="20%;" colspan="2" >{{ryqkb.rygc.rszs2017}}</td>
                            <td width="20%;" colspan="2" >{{ryqkb.rygc.rszs2018}}</td>
                            <td width="20%;" colspan="2" >{{ryqkb.rygc.rszs2019}}</td>
                            <td width="20%;" colspan="1" >{{ryqkb.rygc.rszs2020}}</td>
                            </tr>
                        </tbody>
                        <thead>
                            <tr> 
                            <td width="100%;" colspan="9" class="detailtitle">人员构成情况</td>
                            </tr>
                            <tr> 
                            <td width="15%;" rowspan="2" class="detailtitle">总数</td>
                            <td width="35%;" colspan="4" class="detailtitle">学历构成</td>
                            <td width="35%;" colspan="3" class="detailtitle">岗位构成</td>
                            <td width="15%;" rowspan="2" class="detailtitle">社会保障参保人数</td>
                            </tr>
                            <tr> 
                            <td width="8.75%;" class="detailtitle">大专以下</td>
                            <td width="8.75%;" class="detailtitle">大专</td>
                            <td width="8.75%;" class="detailtitle">本科</td>
                            <td width="8.75%;" class="detailtitle">研究生及以上</td>
                            <td width="11.6%;" class="detailtitle">管理人员</td>
                            <td width="11.6%;" class="detailtitle">作业人员</td>
                            <td width="11.6%;" class="detailtitle">市场人员</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>  
                            <td width="15%;" >{{ryqkb.rygc.zs}}</td>
                            <td width="8.75%;" >{{ryqkb.rygc.dzyx}}</td>
                            <td width="8.75%;" >{{ryqkb.rygc.dz}}</td>
                            <td width="8.75%;" >{{ryqkb.rygc.bk}}</td>
                            <td width="8.75%;" >{{ryqkb.rygc.yjs}}</td>
                            <td width="11.6%;" >{{ryqkb.rygc.gl}}</td>
                            <td width="11.6%;" >{{ryqkb.rygc.zy}}</td>
                            <td width="11.6%;" >{{ryqkb.rygc.sc}}</td>
                            <td width="15%;" >{{ryqkb.rygc.cb}}</td>
                            </tr>
                        </tbody>
                        <thead>
                            <tr> 
                            <td width="100%;" colspan="9" class="detailtitle">管理人员基本情况*</td>
                            </tr>
                            <tr> 
                            <td width="10%;" class="detailtitle">序号</td>
                            <td width="10%;" class="detailtitle">姓名</td>
                            <td width="5%;" class="detailtitle">性别</td>
                            <td width="20%;" class="detailtitle">个人身份证号码</td>
                            <td width="15%;" class="detailtitle">现任职务</td>
                            <td width="10%;" class="detailtitle">最高学历</td>
                            <td width="15%;" class="detailtitle">同业从业年限</td>
                            <td width="15%;" colspan="2" class="detailtitle">入职公司时间</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>  
                            <td width="10%;">{{ryqkb.glry.xh}}</td>
                            <td width="10%;" @click="dialogVisible=true">{{ryqkb.glry.name}}</td>
                            <td width="5%;">{{ryqkb.glry.sex}}</td> 
                            <td width="20%;">{{ryqkb.glry.no}}</td>
                            <td width="15%;">{{ryqkb.glry.zw}}</td>
                            <td width="10%;">{{ryqkb.glry.xl}}</td>
                            <td width="15%;">{{ryqkb.glry.year}}</td>
                            <td width="15%;" colspan="2">{{ryqkb.glry.comDate}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="M2-M3-jjhj-title" style="margin-top:20px;">企业资质情况-内部</div>
                <el-table
                :data="tableData1"
                border
                style="width: 100%;margin-top:10px;">
                    <el-table-column
                    prop="sn"
                    label="序号"
                    align="center"
                    width="80">
                    </el-table-column>
                    <el-table-column
                    prop="cplb"
                    label="资质类型"
                    align="center"
                    width="300">
                    </el-table-column>
                    <el-table-column
                    prop="yzmc"
                    label="认证机构"
                    align="center"
                    width="180">
                    </el-table-column>
                    <el-table-column
                    prop="yzbh"
                    label="认证编码"
                    align="center"
                    width="300">
                    </el-table-column>
                    <el-table-column
                    prop="rzksDate"
                    label="认证开始时间"
                    align="center"
                    width="120">
                    </el-table-column>
                    <el-table-column
                    prop="rzsxDate"
                    label="认证结束时间"
                    align="center"
                    width="120">
                    </el-table-column>
                    <el-table-column
                    prop="from"
                    align="center"
                    min-width="180"
                    label="来源">
                    </el-table-column>
                </el-table>
                <div class="M2-M3-jjhj-title" style="margin-top:20px;">企业知识产权-外部</div>
                <div class="M2-M3-jjhj-title" style="margin-top:20px;font-size:14px;">专利信息 （共<span style="color:#447AFF">10261</span>条数据）</div>
                <el-table
                :data="zlxx"
                border
                style="width: 100%;margin-top:10px;">
                    <el-table-column
                    prop="ptype"
                    label="专利类型"
                    align="center"
                    width="80">
                    </el-table-column>
                    <el-table-column
                    prop="patname"
                    align="center"
                    min-width="200"
                    label="专利名"
                    >
                    </el-table-column>
                    <el-table-column
                    prop="sqh"
                    label="申请号"
                    align="center"
                    width="120">
                    </el-table-column>
                    <el-table-column
                    prop="from"
                    label="代理机构"
                    align="center"
                    width="140">
                    </el-table-column>
                    <el-table-column
                    align="center"
                    width="80"
                    label="详情">
                    <template slot-scope="scope"> 
                        <span @click="zlxxClick(scope.row)">详情</span>
                    </template>
                    </el-table-column>
                </el-table>

                <div class="M2-M3-jjhj-title" style="margin-top:20px;font-size:14px;">作品著作权 （共<span style="color:#447AFF">4</span>条数据）</div>
                <el-table
                :data="zpzzq"
                border
                style="width: 100%;margin-top:10px;">
                    <el-table-column
                    prop="wname"
                    align="center"
                    min-width="200"
                    label="作品名称"
                    >
                    </el-table-column>
                    <el-table-column
                    prop="wtype"
                    label="作品类型"
                    align="center"
                    width="120">
                    </el-table-column>
                    <el-table-column
                    prop="wnum"
                    label="登记号" 
                    align="center"
                    width="200"> 
                    </el-table-column>
                    <el-table-column
                    prop="cdate"
                    label="创作完成日期"
                    align="center" 
                    width="140">
                    </el-table-column>
                </el-table>

                <!-- <div class="M2-M3-jjhj-title" style="margin-top:20px;font-size:14px;">集成电路布图专有权 （共<span style="color:#447AFF">4</span>条数据）</div>
                <el-table
                :data="tableData1"
                border
                style="width: 100%;margin-top:10px;">
                    <el-table-column
                    prop="sn"
                    label="布局设计名称"
                    align="center"
                    min-width="200"
                    >
                    </el-table-column>
                    <el-table-column
                    prop="title"
                    align="center"
                    width="140"
                    label="创作人"
                    >
                    </el-table-column>
                    <el-table-column
                    prop="date"
                    label="申请日"
                    align="center"
                    width="120">
                    </el-table-column>
                    <el-table-column
                    prop="from"
                    label="公告号"
                    align="center"
                    width="140">
                    </el-table-column>
                    <el-table-column
                    align="center"
                    width="80"
                    label="详情">
                    <template>
                        <span>详情</span> 
                    </template>
                    </el-table-column>
                </el-table> -->

                <div class="M2-M3-jjhj-title" style="margin-top:20px;font-size:14px;">软件著作权（共<span style="color:#447AFF">4</span>条数据）</div>
                <el-table
                :data="rzzzqtable"
                border
                style="width: 100%;margin-top:10px;">
                    <el-table-column
                    prop="sname"
                    label="软件名称"
                    align="center"
                    min-width="200"
                    >
                    </el-table-column>
                    <el-table-column
                    prop="snum"
                    label="登记号"
                    align="center"
                    width="120">
                    </el-table-column>
                    <el-table-column
                    prop="pdate"
                    label="首发日期"
                    align="center"
                    width="140">
                    </el-table-column>
                    <el-table-column
                    prop="vnum"
                    align="center"
                    width="80"
                    label="版本号">
                    <template>
                    </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="M2-M3-jjhj-Edit">
                <div class="credit-title">
                    <div>
                    <div class="iconImg">
                        <img src="../../../assets/images/credit/credit-Model/经济@2x.png">
                    </div>
                    <span>企业素质</span> 
                    </div>
                    <div>
                    <div class="credit-title-todetail" ></div>
                    </div>
                </div>

                <div class="M2-M3-jjhj-title">1.经营资质和认证</div>

                <m-slider :choseIndex.sync="slider6" :setIndex="6"></m-slider>
    
                <div style="margin-top:40px;margin-bottom:20px;">
                    <el-input
                        type="textarea"
                        :rows="5"
                        placeholder="（请分析师对该指标信用评分进行解释说明）"
                        v-model="textarea">
                    </el-input>
                </div> 

                <div class="M2-M3-jjhj-title">2.人员情况</div>
                <div class="M2-M3-jjhj-title" style="font-size:14px">2.1  人员构成情况</div>

                <m-slider :choseIndex.sync="slider7" :setIndex="7"></m-slider>

                <div class="M2-M3-jjhj-title" style="font-size:14px">2.2  人员素质</div>

                <m-slider :choseIndex.sync="slider8" :setIndex="8"></m-slider>
    
                <div style="margin-top:40px;margin-bottom:20px;">
                    <el-input
                        type="textarea"
                        :rows="5"
                        placeholder="（请分析师对该指标信用评分进行解释说明）"
                        v-model="textarea2">
                    </el-input>
                </div> 

                
                <div class="M2-M3-jjhj-title">3.内部治理</div>
                <div class="M2-M3-jjhj-title" style="font-size:14px">3.1  股权结构</div>

                <m-slider :choseIndex.sync="slider9" :setIndex="9"></m-slider>

                <div class="M2-M3-jjhj-title" style="font-size:14px">3.2  内控制度</div>

                <m-slider :choseIndex.sync="slider10" :setIndex="10"></m-slider>
    
                <div style="margin-top:40px;margin-bottom:20px;">
                    <el-input
                        type="textarea"
                        :rows="5"
                        placeholder="（请分析师对该指标信用评分进行解释说明）"
                        v-model="textarea3">
                    </el-input>
                </div> 

                <div class="M2-M3-jjhj-title">4.研发能力</div>
                <div class="M2-M3-jjhj-title" style="font-size:14px">4.1  研发体系</div>

                <m-slider :choseIndex.sync="slider11" :setIndex="11"></m-slider>

                <div class="M2-M3-jjhj-title" style="font-size:14px">4.2  研发成果</div>

                <m-slider :choseIndex.sync="slider12" :setIndex="12"></m-slider>
    
                <div style="margin-top:40px;margin-bottom:20px;">
                    <el-input
                        type="textarea"
                        :rows="5"
                        placeholder="（请分析师对该指标信用评分进行解释说明）"
                        v-model="textarea4">
                    </el-input>
                </div> 
            </div> 
        </div>
        <div class="M2-M3-jjhj-buttonList">
            <el-button type="primary" size="small" @click="save">保存</el-button>
            <el-button size="small" @click="routerTo('M2toM3HYHJ')" icon="el-icon-arrow-left">上一页</el-button>
            <el-button size="small" @click="routerTo('M2toM3YWYY')">下一页<i class="el-icon-arrow-right el-icon--right"></i></el-button>
        </div>
    </div>
</template>
<script>
import MSlider from '../component/M2-slider' 
// import {getEntData} from '@@/api/credit'
// import { getFirmID } from '@@/api/credit'
// import {reportApi} from '@@/api/report'
export default { 
    name:'M2toM3QYSZ',
    components:{MSlider},
    data() {
      return {
        dialogVisible:false,
        ryqkb:{
            rygc:{},
            glry:{}
        },
        tableData: [{
            fl:"主营业务",
            sy:"中药生产及药品贸易",
            code:"",
            c27:"",
        }],
         tableData1: [],
         zlxx:[],
         rzzzqtable:[],
        textarea:'',
        textarea2:'',
        textarea3:'',
        textarea4:'',

        slider6:'',
        slider7:'',
        slider8:'', 
        slider9:'',
        slider10:'',
        slider11:'',
        slider12:'',

        entName:'',
        zlshow:false,
        zlDetail:'',

        zpzzq:[]
      }
    },
    // watch:{ 
    //     $route(to,from){
    //         for(let i in this.routeTags){
    //             if(this.routeTags[i].meta.name == from.meta.name){
    //                 this.$store.commit("delRouteTags", i); 
    //             }
    //         }
    //     }
    // }, 
    mounted(){
        var entName = localStorage.getItem('dupontEntName')
        var entData = JSON.parse(localStorage.getItem('dupontEntData'+entName))
        var arr = entData.m2point
        var data = JSON.parse(entData.rlres)

        this.ryqkb.rygc = data.cyry
        this.ryqkb.glry = data.hxgl

        var arr = entData.m2point?entData.m2point:[]
        this.slider6 = arr[6]?arr[6]:entData.M1Point3
        this.slider7 = arr[7]?arr[7]:entData.M1Point3
        this.slider8 = arr[8]?arr[8]:entData.M1Point3
        this.slider9 = arr[9]?arr[9]:entData.M1Point3
        this.slider10 = arr[10]?arr[10]:entData.M1Point3
        this.slider11 = arr[11]?arr[11]:entData.M1Point3
        this.slider12 = arr[12]?arr[12]:entData.M1Point3

        this.textarea=entData.qyszText1
        this.textarea2=entData.qyszText2
        this.textarea3=entData.qyszText3
        this.textarea4=entData.qyszText4

        var data = JSON.parse(entData.m1JsonData)
        this.getEntNb(data.entName)
        this.getZlxx(data.entName)
    },
    methods:{ 
        zlxxClick(item){
            this.zlshow = true
            this.zlDetail = item
        },
        getZlxx(entName){ 
         this.$axios({
                url: "/report/getEntidDb",
                method: "post",
                data:{ ntype:'1', selectVal: entName},
            }).then(res=>{
             if(res.state != "error"){
                this.$axios({
                    url: "/report/reportApiDb",
                    method: "post",
                    data:{entid: res.msg,method: "data",nodetype: "F1001"},
                }).then(res=>{
                    this.zlxx = res.F1001.data
                })
                this.$axios({
                    url: "/report/reportApiDb",
                    method: "post",
                    data:{entid: res.msg,method: "data",nodetype: "F1004"},
                }).then(res=>{
                    this.zpzzq = res.F1004.data
                })
                this.$axios({
                    url: "/report/reportApiDb",
                    method: "post",
                    data:{entid: res.msg,method: "data",nodetype: "F1006"},
                }).then(res=>{
                    this.rzzzqtable = res.F1006.data
                })
             } 
         })
        },
        getEntNb(entname){
            this.$axios({
                url: "/dupont/getEntData",
                method: "post",
                data:{entname:entname},
            }).then(res=>{
                this.tableData1 = res.主体资质.list
            })  
        },
        save(){
            var entName = localStorage.getItem('dupontEntName')
            var entData = JSON.parse(localStorage.getItem('dupontEntData'+entName))

            this.$message({
                message: this.$store.state.saveText,
                type: 'success' 
            });
 
            entData.m2point = entData.m2point?entData.m2point:[]
            entData.m2point[6] = entData.m2point[6]?entData.m2point[6]:entData.M1Point3
            entData.m2point[7] = entData.m2point[7]?entData.m2point[7]:entData.M1Point3
            entData.m2point[8] = entData.m2point[8]?entData.m2point[8]:entData.M1Point3
            entData.m2point[9] = entData.m2point[9]?entData.m2point[9]:entData.M1Point3
            entData.m2point[10] = entData.m2point[10]?entData.m2point[10]:entData.M1Point3
            entData.m2point[11] = entData.m2point[11]?entData.m2point[11]:entData.M1Point3
            entData.m2point[12] = entData.m2point[12]?entData.m2point[12]:entData.M1Point3

            entData.qyszText1 = this.textarea
            entData.qyszText2 = this.textarea2
            entData.qyszText3 = this.textarea3
            entData.qyszText4 = this.textarea4

            localStorage.setItem('dupontEntData'+entName,JSON.stringify(entData))

            // this.$message({
            //     message: this.$store.state.saveText,
            //     type: 'success' 
            // }); 
            // localStorage.setItem('qyszText1',this.textarea)
            // localStorage.setItem('qyszText2',this.textarea2)
            // localStorage.setItem('qyszText3',this.textarea3)
            // localStorage.setItem('qyszText4',this.textarea4)
        },
        routerTo(name){
            this.save()
            this.$router.push({name:name})
        },
    }
}
</script>
<style lang="less" scoped>
@import url('../../../assets/css/table.css'); 
@media screen and (max-width: 1300px) {
    .M2-M3-content{
        height: 100%;
    } 
    .M2-M3-jjhj-chart,.M2-M3-jjhj-Edit{
        height: calc(~"100%");
    }
 
}
@media screen and (min-width: 1300px) {
    .M2-M3-content{
         height: calc(~"100vh - 236px");
     }
    .M2-M3-jjhj-chart,.M2-M3-jjhj-Edit{
        overflow-x: hidden;
    //   height: calc(~"100vh - 176px");
    } 
}
.M2-M3-jjhj{ 
    width: 100%;
    // display: flex;
    // justify-content: space-between;
    // flex-wrap: wrap;
    >.M2-M3-content{
        // height: calc(~"100vh - 236px");
        display: flex;
        justify-content: space-between;
    }
    .tableBox{
        width: 100%;
        overflow-x: auto;
    }
    .M2-M3-jjhj-title{
        font-size: 16px;
        font-weight: bold;
        line-height: 27px;
        color: #30343D;
    }
    .M2-M3-jjhj-chart{
        width: 60%;
        height: 100%;
        overflow-x: hidden;
        margin-right: 20px; 
        >#zx-chart{
            margin-top:20px;
            width: 100%; 
            height: 615px;
        }
    }
    .M2-M3-jjhj-Edit{ 
        width: 40%;
        height: 100%;
        overflow-x: hidden;
    }
    .M2-M3-jjhj-buttonList{
        display: flex; 
        margin-top: 20px;
        justify-content: flex-end;
    }
}
</style>